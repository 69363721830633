<template>
  <a-drawer
    :visible="this.$store.state.goodsEditShow"
    @close="onClose"
    :body-style="{ paddingBottom: '80px' }"
  >
    <a-page-header title="设置平台补贴礼金" :sub-title="'('+id+')'"> </a-page-header>
    <a-form :form="form" layout="vertical">
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="平台补贴礼金">
            <a-input-number
              style="width:100%"
              min=1
              v-decorator="[
                'amount',
                {
                  rules: [{ required: true, message: '请输入平台补贴礼金！' }],
                },
              ]"
              placeholder="请输入平台补贴礼金，大于1元，小于预估佣金的60%"
            >
            </a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div
      :style="{
        height:'80px',
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        padding: '22px 40px 0 0',
        background: '#f8f8f8',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-button :style="{ marginRight: '10px',padding:'0 50px',height:'36px',borderCcolor:'#dbdbdb' }"  @click="onClose">
        取消
      </a-button>
      <a-button type="primary" :style="{padding:'0 50px',height:'36px' }" @click="onSure"> 确定 </a-button>
    </div>
  </a-drawer>
</template>
<script>
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      id: "",
    };
  },
  mounted() {},
  computed: {
    goodsEditShow() {
      return this.$store.state.goodsEditShow;
    },
  },
  watch: {
    goodsEditShow: {
      handler(valnew) {
        if (valnew) {
          this.form.resetFields();
          this.id = this.$store.state.goodsId;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.$store.commit("changeGoodsEditShow", false);
    },
    onSure() {
      var that = this;
      that.form.validateFields((error, values) => {
        if (!error) {
          var data = values;
            data.id = that.id;
            that.axios.post("/api/admin/goods/setMkSubsidyAmount", data).then((res) => {
              that.confirmLoading = false;
              if (that.$code(res)) {
                that.$message.success("设置成功");
                this.$store.commit("changeGoodsEditShow", false);
                this.$store.commit("changeCanfreshgoods", true);
              }
            });
        } else {
          that.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style scoped>
/deep/.ant-drawer-body {
  padding: 84px 40px;
  width: 100%;
  box-sizing: border-box;
}
.ant-page-header-ghost {
  position: absolute;
  min-height: 60px;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  z-index: 1;
}
/deep/.ant-drawer-content-wrapper {
  width: 100% !important;
  min-width: 320px;
  max-width: 700px;
}
.ant-input,/deep/.ant-select-selection--single, /deep/.ant-select-selection__rendered, /deep/.ant-cascader-input.ant-input{
  width:100%
}
.ant-form-item{
  height: auto;
}
</style>
