var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-drawer',{attrs:{"visible":this.$store.state.goodsEditShow,"body-style":{ paddingBottom: '80px' }},on:{"close":_vm.onClose}},[_c('a-page-header',{attrs:{"title":"设置平台补贴礼金","sub-title":'('+_vm.id+')'}}),_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"平台补贴礼金"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'amount',
              {
                rules: [{ required: true, message: '请输入平台补贴礼金！' }],
              } ]),expression:"[\n              'amount',\n              {\n                rules: [{ required: true, message: '请输入平台补贴礼金！' }],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"min":"1","placeholder":"请输入平台补贴礼金，大于1元，小于预估佣金的60%"}})],1)],1)],1)],1),_c('div',{style:({
      height:'80px',
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      padding: '22px 40px 0 0',
      background: '#f8f8f8',
      textAlign: 'right',
      zIndex: 1,
    })},[_c('a-button',{style:({ marginRight: '10px',padding:'0 50px',height:'36px',borderCcolor:'#dbdbdb' }),on:{"click":_vm.onClose}},[_vm._v(" 取消 ")]),_c('a-button',{style:({padding:'0 50px',height:'36px' }),attrs:{"type":"primary"},on:{"click":_vm.onSure}},[_vm._v(" 确定 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }