<template>
  <div class="container" ref="box">
    <div ref="header" class="header pageHeader">
      <a-page-header title="商品管理"> </a-page-header>
    </div>
    <div ref="form" class="form">
      <a-form :form="form" layout="inline" ref="form">
        <a-form-item>
          <a-input
            v-decorator="['title', { rules: [{ required: false }] }]"
            placeholder="淘宝ID/商品描述"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit" @click="getSearch">
            搜索
          </a-button>
        </a-form-item>
      </a-form>
      <!-- <a-button class="buttonImg" @click="handleCell('新增', num)">
        <img src="../assets/add.png" alt="" />
        新增
      </a-button> -->
    </div>
    <a-table
      :columns="columns"
      size="middle"
      :scroll="{ x: 700, y: table_h }"
      :pagination="false"
      :data-source="list"
      :row-key="(record) => record.id"
    >
      <div slot="goodsInfo" slot-scope="goodsInfo, num" class="goodsInfo">
        <img :src="num.mainPic" alt="" />
        <div>
          <div>
            <a-tooltip placement="top" :title="goodsInfo"
              ><p>{{ goodsInfo }}</p></a-tooltip
            >
            <div>
              <span>￥{{ num.originalPrice }}</span>
            </div>
          </div>
          <p>
            <span style="color: rgba(253, 105, 150)">{{
              num.shopType == 1 ? "天猫" : "淘宝"
            }}</span
            >{{ num.brandName ? " | " + num.brandName : "" }}
          </p>
        </div>
      </div>

      <span
        slot="mk_subsidy_amount"
        slot-scope="mk_subsidy_amount, num"
        @click="changeMkSubsidyAmount(num)"
      >
        <span>{{ mk_subsidy_amount }}</span>
        <a-icon
          type="edit"
          style="color: rgba(253, 105, 150); padding-left: 5px"
        />
      </span>
    </a-table>
    <div ref="pagination" style="overflow: hidden">
      <a-pagination
        v-if="pageshow"
        :default-current="currentPage"
        :defaultPageSize="defaultPageSize"
        :page-size-options="pageSizeOptions"
        show-size-changer
        :total="total_num"
        @showSizeChange="onShowSizeChange"
        @change="onPageChange"
        :item-render="itemRender"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
    <Edit v-if="$store.state.goodsEditShow" />
  </div>
</template>
<script>
import Edit from "@/components/goods/edit.vue";
export default {
  name: "User",
  components: {
    Edit,
  },
  data() {
    return {
      columns: [
        {
          title: "淘宝ID",
          key: "goodsId",
          dataIndex: "goodsId",
          align: "center",
        },
        {
          title: "商品信息",
          key: "title",
          dataIndex: "title",
          scopedSlots: { customRender: "goodsInfo" },
          align: "center",
          width: "270px",
        },
        {
          title: "券后价",
          key: "actualPrice",
          dataIndex: "actualPrice",
          align: "center",
        },
        {
          title: "平台补贴礼金",
          key: "mk_subsidy_amount",
          dataIndex: "mk_subsidy_amount",
          scopedSlots: { customRender: "mk_subsidy_amount" },
          align: "center",
        },
        {
          title: "预估佣金",
          key: "estimate_fee",
          dataIndex: "estimate_fee",
          align: "center",
        },
      ],
      table_h: "",
      list: [],
      pageshow: true,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: this.$store.state.defaultPageSize,
      pageSizeOptions: this.$store.state.pageSizeOptions,
      form: this.$form.createForm(this, { name: "search" }),
      search: {},
    };
  },// 监听是否刷新列表
  computed: {
    canfreshgoods() {
      return this.$store.state.canfreshgoods;
    },
  },
  watch: {
    canfreshgoods: {
      handler(val) {
        if (val) {
          this.getSearch();
          this.$store.commit("changeCanfreshgoods", false);
        }
      },
      immediate: true,
    },
    list: {
      handler(val, val1) {
        if (val != val1) {
          this.changeTableH();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getSearch();
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.header.offsetHeight -
        this.$refs.form.offsetHeight -
        this.$refs.pagination.offsetHeight -
        45;
      this.$refs.box.style.display = "flex";
      if (this.table_h <= 250) {
        this.table_h = 400;
        this.$refs.box.style.display = "block";
      }
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.getList(that.search);
    },
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      this.defaultPageSize = pageSize;
      this.$store.commit("changedefaultPageSize", pageSize);
      window.localStorage.setItem("defaultPageSize", pageSize);
      this.getSearch();
    },
    //获取列表
    getSearch() {
      var that = this;
      that.currentPage = 1;
      that.form.validateFields((error, values) => {
        for (var key in values) {
          if (!values[key]) {
            delete values[key];
          }
        }
        that.search = values;
        that.getList(that.search);
      });
    },
    getList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/api/admin/goods/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var list = res.data.resultObject.data;
            that.list = list;
            that.total_num = res.data.resultObject.hasPages
              ? res.data.resultObject.current_page * data.limit + 1
              : res.data.resultObject.current_page * data.limit;
            that.total_num = that.total_num || 0;
            that.currentPage = res.data.resultObject.current_page;
          }
        });
      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    changeMkSubsidyAmount(num) {
      this.$store.commit("changeGoodsEditShow", true);
      this.$store.commit("changeGoodsId", num.goodsId);
    },
  },
};
</script>
<style scoped>
/deep/.pageHeader .ant-page-header,
/deep/.pageHeader .ant-page-header-heading {
  height: auto;
  border: 0 !important;
}
/deep/.ant-form-item {
  margin-right: 10px;
}

/deep/.ant-table-body::-webkit-scrollbar {
  background-color: #fff;
  width: 10px;
  height: 10px;
}
/deep/.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f2f5;
}
/deep/.ant-pagination .ant-pagination-item,
/deep/.ant-pagination .ant-pagination-jump-prev,
/deep/.ant-pagination .ant-pagination-jump-next {
  display: none;
}
.ant-btn {
  height: 36px;
  border-color: #f8f8f8;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #666;
  border-color: rgba(253, 105, 150);
}
form .ant-btn:hover,
form .ant-btn:focus {
  color: #fff;
}
.ant-table .ant-btn {
  height: 30px;
  padding: 0 20px;
}

.goodsInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 250px;
}
.goodsInfo img {
  width: 80px;
  height: 80px;
}
.goodsInfo > div {
  width: 165px;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 80px;
}
.goodsInfo > div > div {
  width: 165px;
  text-align: left;
}
.goodsInfo > div > div span {
  color: rgba(253, 105, 150);
}
.goodsInfo p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
}
</style>